@use "../config/" as *;
.waf-standings {
  padding: 0 0 var(--space-20);
  margin-bottom: 0;
  @extend %accordion-common;
  @extend %page-head-blue-bg;
  .head-wrap {
    margin-bottom: 11rem;
  }
  .title {
    @extend %mb-4;
    @extend %neutral-0;
  }
  .desc {
    @extend %mb-2;
    @extend %neutral-5;
    @extend %body-l;
  }
  .meta {
    @extend %flex;
    @extend %flex-wrap;
    @extend %gap-4;
    &-item {
      @include flex-config(inline-flex, null, null, center);
      @extend %neutral-5;
      @extend %text-m;
      @extend %gap-1;
      &::before {
        font: 1.6rem/1 $font-icon;
      }
    }
  }
  .excel::before {
    content: map-get($map: $icons, $key: excel);
  }
  .pdf::before {
    content: map-get($map: $icons, $key: document-blank);
  }
  .web::before {
    content: map-get($map: $icons, $key: globe);
  }
  .waf-body {
    margin-top: -7.9rem;
    @extend %p-6-4;
  }
  .accordion {
    &-body {
      @extend %mr-4-neg;
    }
    &-item:not(:last-child) {
      @extend %mb-8;
    }
    &-header[aria-expanded="true"] .btn-text {
      -webkit-line-clamp: unset;
    }
  }
  table,
  td,
  th,
  tr {
    border: 0;
  }
  table {
    overflow: hidden;
    @extend %mr-4;
    @extend %border-radius-m;
    @extend %relative;
    &::after {
      content: "";
      pointer-events: none;
      border: .1rem solid var(--neutral-20);
      @include position-combo(inset);
      @extend %border-radius-m;
    }
  }
  thead {
    @extend %bg-neutral-5;
    &:first-child {
      border-radius: var(--border-radius-m) var(--border-radius-m) 0 0;
    }
  }
  tbody:last-child tr:last-child {
    border-bottom: unset;
  }
  tr {
    border-bottom: .1rem solid var(--neutral-20);
  }
  td,
  th {
    height: 5.6rem;
    min-width: 8rem;
    @extend %p-0-2;
    @extend %text-center;
  }
  th {
    @extend %neutral-70;
    @extend %text-sb;
  }
  td {
    @extend %neutral-100;
    @extend %text-s;
    span {
      @extend %w-100;
      @extend %d-inline-block;
    }
  }
  .has-link {
    white-space: nowrap;
  }
  .year-title {
    text-align: left;
    gap: var(--space-2);
    @include flex-config(flex, row, null, stretch);
    span {
      width: auto;
      @include flex-config(flex, row, null, center);
    }
  }
  .table {
    &-wrapper {
      overflow-x: auto;
      + .table-wrapper{
        @extend %mt-10;
      }
    }
    &-title {
      font-size: 1.6rem;
      @include truncate-text(2);
      @extend %p-4;
      @extend %neutral-100;
      @extend %text-center;
    }
  }
  .link {
    @extend %d-inline-block;
    @extend %primary-50;
    @extend %font-0;
    &::before {
      font: 2rem/1 $font-icon;
    }
    + .link {
      @extend %ml-6;
    }
    &-text-icon {
      font-size: 1.2rem;
      @include flex-config(inline-flex, row-reverse, null, center);
      @extend %gap-1;
    }
  }
}
@include mq(col-tablet) {
  .waf-standings {
    padding-bottom: var(--space-24);
    .head-wrap {
      margin-bottom: 26.5rem;
      .title {
        font-size: 4rem;
        line-height: 1.2;
      }
    }
    .waf-body {
      padding: var(--container-white-space);
      margin-top: -8rem;
    }
    .accordion {
      &-body {
        margin-right: 0;
      }
      &-item:not(:last-child) {
        margin-bottom: calc(var(--container-white-space) * .8);
      }
      &-header {
        &[aria-expanded='true'] {
          margin-bottom: calc(var(--container-white-space) / 2);
        }
        .btn-text {
          font-size: 2.4rem;
        }
      }
    }
    table {
      width: 100%;
      margin-right: 0;
      table-layout: fixed;
    }
    td,
    th {
      height: 7.2rem;
      padding-inline: var(--space-4);
      font-size: 1.4rem;
    }
    .table-title {
      padding-block: var(--space-6);
      font-size: 2rem;
    }
    .link-text-icon {
      font-size: 1.4rem;
      font-weight: 700;
    }
  }
}

@include mq(col-desktop){
  .waf-standings{
    .year-title {
      gap: var(--space-22);
    }
  }
}